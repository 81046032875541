<template>
	<div>
		<div class="w-full gap-10 d-flex overflow-hidden">
			<div class="px-7 w-33">
				<div>User Type</div>
				<v-radio-group v-model="userType" row class="mt-0 pt-2">
					<v-radio label="All" name="active" value="all"></v-radio>
					<v-radio label="Individual" name="active" value="individual"></v-radio>
				</v-radio-group>
				<div v-if="userType == 'individual'">
					<div class="primaryColor fsize14 pb-1">
						User ID ( Ex: 10234,12049,76543 )
					</div>
					<v-text-field dense @input="userId ? (userId = userId.toUpperCase()) : null" v-model="userId"
						placeholder="Enter User Id" outlined class="tradeCalls">
					</v-text-field>
					<div class="min-h-14">
						<span class="fsize10 red--text" v-if="submitted && userId == ''">User Id is required</span>
					</div>
				</div>
				<div>
					<div class="primaryColor fsize14 pb-1">Basket Name</div>
					<v-text-field dense v-model="basketName" placeholder="Enter Basket Name" outlined
						class="tradeCalls">
					</v-text-field>
					<div class="min-h-14">
						<span class="fsize10 red--text" v-if="submitted && basketName == ''">Basket Name is
							required</span>
					</div>
				</div>
				<div class="d-flex gap-3">
					<div class="w-50">
						<div class="primaryColor fsize14 pb-1">Expiry Date</div>
						<v-menu v-model="dateMenu1" :close-on-content-click="false" transition="scale-transition"
							offset-y max-width="290px" min-width="auto">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field @click:append="dateMenu1 = true" clearable class="apiKeyInput tradeCalls"
									v-model="computedDateFormatted1" outlined persistent-hint
									:menu-props="{ bottom: true, offsetY: true }" placeholder="Select Expiry Date"
									append-icon="mdi-calendar" readonly dense v-bind="attrs" v-on="on"></v-text-field>
							</template>
							<v-date-picker :min="new Date().toISOString().substr(0, 10)" v-model="fromDate"
								:show-current="true" no-title @input="dateMenu1 = false"></v-date-picker>
						</v-menu>
					</div>
					<div>
						<div class="primaryColor fsize14 pb-1">Description</div>
						<v-text-field dense v-model="description" placeholder="Enter Description" outlined
							class="tradeCalls">
						</v-text-field>
						<div class="min-h-14"></div>
					</div>
				</div>
				<div class="d-flex gap-3">
					<div class="50">
						<div>Send Push Notification</div>
						<v-radio-group id="push" v-model="sendPush" row class="mt-0 pt-2">
							<v-radio label="Yes" name="active" value="Yes"></v-radio>
							<v-radio label="No" name="active" value="No"></v-radio>
						</v-radio-group>
					</div>
					<div v-if="sendPush == 'Yes'">
						<div class="primaryColor fsize14 pb-1">Push Notification Title</div>

						<v-text-field dense v-model="pushNotification" placeholder="Enter title" outlined
							class="tradeCalls">
						</v-text-field>
						<div class="min-h-14">
							<span class="fsize10 red--text"
								v-if="submitted && pushNotification == '' && sendPush == 'Yes'">Notification
								title is required</span>
						</div>
					</div>
				</div>
			</div>

			<div class="w-full px-4">
				<div class="row ma-0 d-flex align-center border-a8a8a8 rounded" id="basketDiv">
					<div class="d-flex justify-space-between input-width w-full">
						<input type="text" name="" id="basketSrcipSearch" v-model="search" :disabled="scripsArray.length > 0"
							class="border-none outline-none input-width px-4 py-2 w-full" placeholder="Search and Add Instrument"
							ref="basketsearch" />
					</div>
				</div> 
				<v-sheet v-if="search.length > 0" v-click-outside="onClickOutside" transition="fade-transition" tile
					class="orderSearchList max-h-140 gtt_search_border rounded" style="width: 63.5%">
					<ul class="pl-0">
						<li @click="searchRowClick(item)" :id="`${item.formattedInsName}_indivdual_basket_li`"
							class="px-2 py-3 border-bottom-light cursor-pointer" v-for="(item, index) in searchList"
							:key="index">
							<div class="d-flex justify-space-between">
								<span class="primaryColor"
									:id="`${item.formattedInsName}_indivdual_basket_search_scrip_name`">{{
					item.formattedInsName }}</span>

								<button class="pa-0 rounded min-width-40" :class="[
					item.exchange == 'NSE'
						? 'buycolor'
						: item.exchange == 'BSE'
							? 'sellcolor'
							: '',
				]" depressed min-width="36" max-width="36" height="26" :id="`${item.formattedInsName}_search_scrip_ex`">
									{{ item.exchange }}
								</button>
							</div>
						</li>
					</ul>
				</v-sheet>

				<div>
					<v-simple-table v-if="scripsArray.length > 0" class="my-4 border-top">
						<thead>
							<tr>
								<th v-for="item in header" :class="item.class"idth="100px">
									{{ item.name }}
								</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, id) in scripsArray">
								<td class="text-left">
									{{ item.tradingSymbol ? item.tradingSymbol : "NA" }}
									&nbsp; <span class="text-[9px]"> {{ item.exchange }}</span>
								</td>

								<td class="text-right">
									{{ item.price ? item.price : "0.00" }}
								</td>
								<td class="text-right">
									{{ item.qty ? item.qty : "0" }}
								</td>
								<td class="text-center">
									{{ item.product ? item.product : "-" }}&nbsp;/&nbsp;{{
					item.priceType ? item.priceType : "-"
				}}
								</td>
								<td class="text-center">
									{{ item.orderType ? item.orderType : "-" }}
								</td>
								<td class="text-center">
									{{ item.transType }}
								</td>

								<td class="text-center cursor-pointer justify-start">
									<span @click="deleteScrip(id)">
										<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
											stroke-width="1.5" stroke="currentColor"
											style="width: 20px !important; height: 20px !important"
											class="mx-auto cursor-pointer">
											<path stroke-linecap="round" stroke-linejoin="round"
												d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
										</svg></span>
								</td>
							</tr>
						</tbody>
					</v-simple-table>
					<div v-else class="text-center py-8">No scrips added</div>
				</div>
			</div>
			<orderWindow v-if="showOrderWindow"></orderWindow>
		</div>
		<div class="d-flex justify-end px-5">
			<v-btn height="40" width="96" depressed outlined color="grey--text"
				class="mr-3 text-capitalize">Cancel</v-btn>
			<v-btn :loading="btnLoader" @click="submitHandle()" height="40" width="96" depressed color="primary"
				class="mr-3 text-capitalize">Submit</v-btn>
		</div>
	</div>
</template>
<script>
import orderWindow from "./orderWindow.vue";
//import icons from "../../components/icons.vue";
// import {
//   Listbox,
//   ListboxButton,
//   ListboxLabel,
//   ListboxOption,
//   ListboxOptions,
// } from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";
import { mapGetters, mapState } from "vuex";
import commonFunctions from '../mixins/commonFunctions'
export default {
	data: () => ({
		userTypes: [
			{ id: "individual", title: "Individual", value: "individual" },
			{ id: "all", title: "All", value: "all" },
		],
		deleteSvg: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 mx-auto cursor-pointer">
	  <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
	</svg>
	`,
		yesOrNo: [
			{ id: "Yes", title: "Yes", value: 1 },
			{ id: "No", title: "No", value: 0 },
		],
		userType: "individual",
		userId: "",
		submitted: false,
		popover: {
			visibility: "click",
			placement: "bottom-start",
		},
		basketName: "",
		expiryDate: "",
		description: "",
		sendPush: "Yes",
		notificationTitle: "",
		pushNotification: "",
		qty: "",
		dateMenu1: false,
		scripName: "",
		price: "",
		//: Array<Object> = [],
		searchSheet: false,
		btnLoader: false,
		header: [
			{ name: "ScripName", csvKey: "scripName", class:"text-left"},

			{ name: "Price", csvKey: "price", class:"text-right" },
			{ name: "Qty", csvKey: "qty" , class:"text-right"},
			{ name: "Product Type", csvKey: "productType", class:"text-center" },
			{ name: "Order Type", csvKey: "orderType", class:"text-center" },
			{ name: "Transaction Type", csvKey: "transactionType", class:"text-center" },

			{ name: "Delete", csvKey: "delete", class:"text-center" },
		],
		scripSubmitted: false,
		search: "",
		fromDate: new Date().toISOString().substr(0, 10),
		selectedDate: "",
		timerId: 0,
		hideNotificationError: false,
	}),
	mixins:[commonFunctions],
	computed: {
		//...mapGetters("notify", ["getLoader"]),
		// ...mapState("notify", ["scripsArray", "searchList"]),
		...mapState("ow", ["showOrderWindow", "searchList", "scripsArray"]),
		computedDateFormatted1: {
			get() {
				return this.VutifyFormatDate(this.fromDate, '/');
			},
			set() { },
		},
	},
	components: {
		// Listbox,
		// ListboxButton,
		// ListboxLabel,
		// ListboxOption,
		// ListboxOptions,
		// CheckIcon,
		// ChevronUpDownIcon,
		orderWindow,
		//icons,
	},
	watch: {
		search: function (newValue) {
			newValue = newValue.trim();
			clearTimeout(this.timerId);
			if (newValue && newValue.length > 1) {
				this.timerId = setTimeout(() => {
					this.callSearch(newValue);
				}, 500);
			}
		},
	},
	methods: {
		getTransType(type) {
			if (type == "buy") {
				return "B";
			} else {
				return type;
			}
		},
		submitHandle() {
			this.submitted = true;

			let userIdArr = this.userId ? this.userId.split(",") : [];

			// userIdArr.push(this.userId);
			if (this.scripsArray.length == 0) {
				this.$store.commit("SET_SNAKBAR", {
					msg: "Add scrips to continue",
					show: true,
					color: "red darken-1",
					timeout: 5500,
				});
			}
			if (
				this.basketName != "" ||
				this.description != "" ||
				this.expiryDate != "" ||
				this.sendPush == 0 ||
				this.pushNotification != "" ||
				this.scripsArray.length > 0
			) {
				let json = {
					apiKey: "GUGsaYmqEuhiCYF",
					basketName: this.basketName,
					description: this.description,
					userId: userIdArr,
					expiryDate: this.VutifyFormatDate(this.fromDate, '-'),
					pushNotification: this.sendPush == "Yes" ? 1 : 0,
					message: this.description,
					title: this.pushNotification,
					scrips: this.scripsArray,
				};
				this.$store.dispatch("ow/createBasketNotification", json);
			}
		},
		getTransType(trans) {
			let type = trans == "B" ? "BUY" : "S" ? "SELL" : trans;
			return type;
		},
		capitalize() {
			this.userId = this.userId ? this.userId.toUpperCase() : "";
		},
		searchRowClick(list) {
			this.search = "";

			this.callOrderWindowByContract(
				list,
				"buy",
				false,
				"basket",
				false,
				false,
				false,
				false
			);
		},
		async callOrderWindowByContract(
			data,
			orderType,
			isModify,
			page,
			isBasket,
			isBasketModify,
			isRepeat,
			isGtt
		) {
			await this.$store.dispatch("ow/getContract", {
				data,
				orderType,
				isModify,
				page,
				isBasket,
				isBasketModify,
				isRepeat,
				isGtt,
			});
		},
		onClickOutside() {
			this.search = "";
		},
		callSearch(value) {
			var temp = {
				value: value.toString().toUpperCase(),
			};
			clearTimeout(this.timerId);
			this.$store.dispatch("ow/getSearchList", temp);
		},
		switchRadio() {
			this.hideNotificationError = true;
		},
		getMaxDate() {
			let date = new Date();
			let newMonth = date.getMonth() + 1;
			date.setMonth(newMonth);
			return date.toISOString().slice(0, 10);
		},
		getMinDate() {
			return new Date().toISOString().slice(0, 10);
		},
		deleteScrip(index) {
			this.$store.commit("ow/spliceScripsArray", index);
		},
	},
};
</script>
<style>
.orderSearchList {
	position: absolute;
	max-height: 75%;
	overflow-y: auto;
	background: white;
	z-index: 3;
}

.min-h-14 {
	min-height: 14px !important;
}

.orderSearchList::-webkit-scrollbar {
	width: 5px;
}

.orderSearchList::-webkit-scrollbar-thumb {
	--tw-bg-opacity: 2;
	background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
	border-radius: 9999px;
}

.orderSearchList::-webkit-scrollbar-track {
	--tw-bg-opacity: 2;
	background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
	--tw-shadow: 0 10px 15px -3px rgba(255, 255, 255, 0.1),
		0 4px 6px -2px rgba(216, 213, 213, 0.05);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
		var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.searchList {
	position: absolute;
	max-height: 75%;
	overflow-y: auto;
	background: white;
	z-index: 3;
}

.gap-3 {
	gap: 13px !important;
}

.searchList::-webkit-scrollbar {
	width: 5px;
}

.searchList::-webkit-scrollbar-thumb {
	--tw-bg-opacity: 2;
	background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
	border-radius: 9999px;
}

.searchList::-webkit-scrollbar-track {
	--tw-bg-opacity: 2;
	background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
	--tw-shadow: 0 10px 15px -3px rgba(255, 255, 255, 0.1),
		0 4px 6px -2px rgba(216, 213, 213, 0.05);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
		var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.w-400 {
	width: 400px !important;
}

.w-33 {
	width: 44% !important;
}

.pos-relative {
	position: relative;
}

.gap-10 {
	gap: 30px !important;
}

.outline-none {
	outline: none !important;
	box-shadow: none !important;
}

.tradeCalls .v-text-field__details {
	margin-bottom: 0px !important;
	min-height: 0px !important;
}

.tradeCalls .v-messages {
	min-height: 0px !important;
}

.cursor-pointer {
	cursor: pointer;
}

input:disabled {
  cursor: not-allowed;
}
</style>
